import { navigate } from "gatsby"
import { useAtom } from "jotai"
import React, { useEffect, ReactNode, useCallback } from "react"
import { legacyLogin } from "../api/client"
import { userAtom, authAtom, parkingNameAtom } from "../atoms/atom"
import { User } from "../types"

type AuthType = {
  user: User | null
  login: (username: string, password: string) => Promise<void>
  logout: () => void
}

export const useAuth = (): AuthType => {
  const [user, setUser] = useAtom(userAtom)
  const [parkingName, setParkingName] = useAtom(parkingNameAtom)
  const [token, setToken] = useAtom(authAtom)
  useEffect(() => {
    if (token && user) {
      setUser(user)
    }
  }, [])

  const login = useCallback(async (username: string, password: string) => {
    try {
      const result = await legacyLogin(username, password)
      const user: User = {
        name: result.name,
        paymentMethod: result.paymentMethod,
      }
      setToken(window.btoa(`${username}:${password}`))
      setUser(user)
      if (parkingName) {
        navigate(`/${parkingName}/reservation`)
        setParkingName(null)
      } else {
        navigate("/")
      }
    } catch (error) {
      throw new Error("ログインに失敗しました。")
    }
  }, [])

  const logout = useCallback(() => {
    setToken(null)
    setUser(null)
  }, [])

  return { user, login, logout }
}

const AuthGuard = ({ children }: { children: ReactNode }): JSX.Element => {
  const [auth] = useAtom(authAtom)

  useEffect(() => {
    if (!auth) {
      navigate("/signin")
    }
  }, [auth])

  if (!auth) {
    return <div>Loading...</div>
  }

  return <>{children}</>
}

export default AuthGuard
